// PolicyPage.scss
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.policy-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Poppins', sans-serif;
  color: #333;
  line-height: 1.6;
  
  @media (max-width: 768px) {
    padding: 1.5rem;
  }
}

.policy-header {
  text-align: center;
  margin-bottom: 3rem;
  
  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #1e1e1e;
    margin-bottom: 0.5rem;
  }
  
  .policy-subtitle {
    font-size: 0.9rem;
    color: #666;
    font-weight: 400;
  }
}

.policy-section {
  margin-bottom: 2.5rem;
  
  h2 {
    font-size: 1.8rem;
    font-weight: 600;
    color: #1a73e8;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #eaeaea;
  }
  
  h3 {
    font-size: 1.3rem;
    font-weight: 500;
    color: #333;
    margin: 1.5rem 0 0.75rem;
  }
  
  p {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 400;
  }
  
  ul {
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;
    
    li {
      margin-bottom: 0.5rem;
      position: relative;
      
      &::before {
        content: "•";
        color: #1a73e8;
        font-weight: bold;
        display: inline-block;
        width: 1rem;
        margin-left: -1rem;
      }
    }
  }
}

.contact-info {
  background-color: #f9f9f9;
  padding: 1.5rem;
  border-radius: 8px;
  border-left: 4px solid #1a73e8;
  
  p {
    margin-bottom: 0.5rem;
  }
  
  a {
    color: #1a73e8;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease;
    
    &:hover {
      color: darken(#1a73e8, 15%);
      text-decoration: underline;
    }
  }
}

@media (max-width: 576px) {
  .policy-header {
    h1 {
      font-size: 2rem;
    }
  }
  
  .policy-section {
    h2 {
      font-size: 1.5rem;
    }
    
    h3 {
      font-size: 1.2rem;
    }
  }
}